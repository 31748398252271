import { GrayColor, LightColor, H3, Spacing } from '@web/atomic';
import styled, { css, keyframes } from 'styled-components';

export enum BreathWidgetStatus {
  stop,
  inhale,
  inhaleHold,
  exhale,
}

const size = 200;
const circleStyle = css`
  box-sizing: border-box;
  height: ${size}px;
  width: ${size}px;
  border-radius: 50%;
`;
export const BreathBkg = styled.div`
  margin: ${Spacing.Medium} 0;
  padding: ${Spacing.Medium};
  background: ${GrayColor.Neutral};
`;

export const BreathWidgetText = styled(H3)`
  color: ${GrayColor.GrayDark};
`;

export const BreathWidgetClockNumber = styled(H3)`
  color: ${GrayColor.GrayDark};
  font-variant-numeric: tabular-nums;
`;

export const BreathWidgetWrapperCircle = styled.div`
  margin: auto;
  ${circleStyle};
  position: relative;
  background: none;
`;

export const BreathWidgetBkgCircle = styled.div`
  ${circleStyle};
  position: absolute;
  background: ${({ theme }) => theme.color.primary};
  opacity: 0.1;
`;

interface BreathWidgetStyledProps {
  status: BreathWidgetStatus;
  time: number;
}
const BreathWidgetInnerAnimation = css<BreathWidgetStyledProps>`
  animation-name: ${(props) => getInnerAnimation(props.status)};
  animation-duration: ${(props) => `${props.time}s`};
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
`;

export const BreathWidgetInnerCircle = styled.div<BreathWidgetStyledProps>`
  ${circleStyle};
  position: absolute;
  background: ${({ theme }) => theme.color.primary};
  transform: scale(0);
  ${BreathWidgetInnerAnimation};
`;

const BreathWidgetBorderAnimation = css<BreathWidgetStyledProps>`
  animation-name: ${(props) => getBorderAnimation(props.status)};
  animation-duration: ${(props) => `${props.time}s`};
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
`;
export const BreathWidgetBorderCircle = styled.div`
  ${circleStyle};
  position: absolute;
  background: ${GrayColor.White};
  border: ${({ theme }) => `2px solid ${theme.color.primary}`};
  transform: scale(0);
  ${BreathWidgetBorderAnimation};
`;

////////////////////////////////////////////////////////////////////////////////////////////////////

const borderInhale = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;
const borderInhaleHold = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1);
  }
`;
const borderExhale = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
`;
const getBorderAnimation = (status: BreathWidgetStatus) => {
  switch (status) {
    case BreathWidgetStatus.stop:
      return null;
    case BreathWidgetStatus.inhale:
      return borderInhale;
    case BreathWidgetStatus.inhaleHold:
      return borderInhaleHold;
    case BreathWidgetStatus.exhale:
      return borderExhale;
    default:
      throw new Error(`invalid status: ${status}`);
  }
};

const innerInhale = keyframes`
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;
const innerInhaleHold = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(1);
  }
`;
const innerExhale = keyframes`
  from {
    opacity: 0;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
`;
const getInnerAnimation = (status: BreathWidgetStatus) => {
  switch (status) {
    case BreathWidgetStatus.stop:
      return null;
    case BreathWidgetStatus.inhale:
      return innerInhale;
    case BreathWidgetStatus.inhaleHold:
      return innerInhaleHold;
    case BreathWidgetStatus.exhale:
      return innerExhale;
    default:
      throw new Error(`invalid status: ${status}`);
  }
};
