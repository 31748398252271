import { GatsbyButton } from '@components/atm.button/button.component';
import { twoDigit } from '@global/utils/number/two-digit';
import { ShareIconsNewsCell } from '@lp-root/src/components/mol.share-icons-cell/share-icons-cell.component';
import { appPaths } from '@lp-root/src/utils/path';
import { Hbox, Separator } from '@web/atomic';
import { sendDataLayer } from '@web/utils/analytics';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import {
  BreathBkg,
  BreathWidgetBkgCircle,
  BreathWidgetBorderCircle,
  BreathWidgetClockNumber,
  BreathWidgetInnerCircle,
  BreathWidgetStatus,
  BreathWidgetText,
  BreathWidgetWrapperCircle,
} from './breath-widget.component.style';

interface BreathWidgetTimes {
  inhale: number;
  inhaleHold: number;
  exhale: number;
}

const BreathWidget: React.FunctionComponent = () => {
  const inhale = 4;
  const inhaleHold = 7;
  const exhale = 8;
  const [times, setTimes] = React.useState({ inhale, inhaleHold, exhale });
  const [status, setStatus] = React.useState(BreathWidgetStatus.stop);
  const [clock, setClock] = React.useState(0);
  const [timer, setTimer] = React.useState('00:00');
  const [currentStatusStartClock, setCurrentStatusStartClock] = React.useState(0);

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);
  const deepBreathWidgetCanonicalUrl = getCanonicalUrl(data.site.siteMetadata.siteUrl, appPaths.deepBreathWidget.path);

  const started = status !== BreathWidgetStatus.stop;
  let id;
  React.useEffect(() => {
    if (started) {
      id = setInterval(() => {
        setClock((c) => c + 1);
      }, 1000);
    } else {
      clearInterval(id);
      setClock(0);
      setCurrentStatusStartClock(0);
    }

    return () => {
      clearInterval(id);
    };
  }, [started]);

  React.useEffect(() => {
    const toClockFormatMMSS = (): string => {
      const minutes = Math.floor(clock / 60);
      const seconds = clock - minutes * 60;
      return twoDigit(minutes) + ':' + twoDigit(seconds);
    };
    const timerMMSS = toClockFormatMMSS();
    setTimer(timerMMSS);
  }, [clock]);

  const on356Click = () => {
    setTimes({ inhale: 3, inhaleHold: 5, exhale: 6 });
    setStatus(BreathWidgetStatus.inhale);
    sendDataLayer({
      event: 'custom_click',
      id: `ga-BreathWidget-456`,
    });
  };
  const on478Click = () => {
    setTimes({ inhale, inhaleHold, exhale });
    setStatus(BreathWidgetStatus.inhale);
    sendDataLayer({
      event: 'custom_click',
      id: `ga-BreathWidget-478`,
    });
  };
  const onStopClick = () => {
    setStatus(BreathWidgetStatus.stop);
    sendDataLayer({
      event: 'custom_click',
      id: `ga-BreathWidget-stop`,
    });
  };

  const percentage = getPercentageForStatus(status, clock - currentStatusStartClock, times);

  React.useEffect(() => {
    if (percentage !== 1) {
      return;
    }
    setCurrentStatusStartClock(clock);

    const nextStatus = getNextStatus(status, times);
    if (nextStatus !== status) {
      setStatus(nextStatus);
    }
  }, [clock]);

  const statusText = getTitleForStatus(status);
  return (
    <BreathBkg>
      {/* dis-comment to debug */}
      {/* <pre>
        title: {getTitleForStatus(status)} <br />
        cycle time: {clock - currentStatusStartClock} {percentage} <br />
      </pre> */}
      <BreathWidgetText center={true}>{statusText}</BreathWidgetText>
      <BreathWidgetWrapperCircle>
        <BreathWidgetBkgCircle />
        <BreathWidgetBorderCircle status={status} time={getTotalTimeForStatus(status, times)} />
        <BreathWidgetInnerCircle status={status} time={getTotalTimeForStatus(status, times)} />
      </BreathWidgetWrapperCircle>
      <Separator />
      <Hbox hAlign="center">
        {started ? (
          <Hbox.Item wrap>
            <Hbox.Item wrap>
              <BreathWidgetClockNumber center={true}>{timer}</BreathWidgetClockNumber>
              <GatsbyButton kind="primary" onClick={onStopClick} id="ga-breath-widget-stop">
                Parar
              </GatsbyButton>
            </Hbox.Item>
          </Hbox.Item>
        ) : (
          <>
            <Hbox.Item wrap>
              <GatsbyButton kind="primary" onClick={on478Click} id="ga-breath-widget-478">
                4/7/8
              </GatsbyButton>
            </Hbox.Item>
            <Hbox.Separator />
            <Hbox.Item wrap>
              <GatsbyButton kind="secondary" onClick={on356Click} id="ga-breath-widget-356">
                3/5/6
              </GatsbyButton>
            </Hbox.Item>
          </>
        )}
      </Hbox>
      <Separator />
      <Hbox hAlign="center">
        <ShareIconsNewsCell
          shareTyping={''}
          kind="dark"
          identification="breath-widget"
          url={deepBreathWidgetCanonicalUrl}
          text={'Estou usando esse site para fazer respiração profunda'}
          title={'Respiração profunda'}
        />
      </Hbox>
    </BreathBkg>
  );
};

const getTotalTimeForStatus = (currStatus: BreathWidgetStatus, times: BreathWidgetTimes) => {
  switch (currStatus) {
    case BreathWidgetStatus.stop:
      return 0;
    case BreathWidgetStatus.inhale:
      return times.inhale;
    case BreathWidgetStatus.inhaleHold:
      return times.inhaleHold;
    case BreathWidgetStatus.exhale:
      return times.exhale;
    default:
      throw new Error(`invalid status: ${currStatus}`);
  }
};

const getTitleForStatus = (status: BreathWidgetStatus) => {
  switch (status) {
    case BreathWidgetStatus.stop:
      return 'Respiração profunda';
    case BreathWidgetStatus.inhale:
      return 'Inspire';
    case BreathWidgetStatus.inhaleHold:
      return 'Segure';
    case BreathWidgetStatus.exhale:
      return 'Expire';
    default:
      throw new Error(`invalid status: ${status}`);
  }
};

const getPercentageForStatus = (currStatus: BreathWidgetStatus, cycleTime: number, times: BreathWidgetTimes) => {
  switch (currStatus) {
    case BreathWidgetStatus.stop:
      return 0;
    default:
      return cycleTime / getTotalTimeForStatus(currStatus, times);
  }
};

const getNextStatus = (status: BreathWidgetStatus, times: BreathWidgetTimes) => {
  switch (status) {
    case BreathWidgetStatus.stop:
      return BreathWidgetStatus.stop;
    case BreathWidgetStatus.inhale:
      return times.inhaleHold > 0 ? BreathWidgetStatus.inhaleHold : BreathWidgetStatus.exhale;
    case BreathWidgetStatus.inhaleHold:
      return BreathWidgetStatus.exhale;
    case BreathWidgetStatus.exhale:
      return BreathWidgetStatus.inhale;
    default:
      throw new Error(`invalid status: ${status}`);
  }
};

export default BreathWidget;
